.app {
    display: flex;
    height: 100vh;
}

.calendar {
    flex: 3;
    padding: 20px;
}

.todo-list {
    flex: 1;
    padding: 20px;
    background-color: #f0f0f0;
}

.todo-list form {
    margin-bottom: 20px;
}

.todo-list ul {
    list-style-type: none;
    padding: 0;
}

.todo-list li {
    cursor: pointer;
    margin-bottom: 10px;
}